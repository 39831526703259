var util = require('./util');

module.exports = (function() {
	var api = {};


	var home = angular.module('green-home', [
	]);

	home.controller('HomeController', ['$scope', '$rootScope', '$element', '$document', '$window', '$timeout', function($scope, $rootScope, $element, $document, $window, $timeout) {
		var panel = this;
	}]);

	api.home = home;

	return api;
})();
