module.exports = (function() {
	var api = {};

	api.register = function (form) {

		form.directive('mocatext', function(){
			return {
				restricted: 'E',
				scope: {
					model: '=model',
					ngrequired: '='
				},
				link: function(scope, element, attrs, controllers) {
					var panel = this;

					scope.isActive = scope.model !== '';
					scope.required = false;

					if (attrs.required === "")
						scope.required = true;

					if (typeof scope.ngrequired !== "undefined")
						scope.required = scope.ngrequired;

					scope.onEnter = function () {
						scope.isActive = true;
					}

					scope.onLeave = function () {
						if (scope.model == '' || typeof scope.model == 'undefined'){
							scope.isActive = false;
						}
					}

					scope.$watch('ngrequired', function(value){
		                scope.required = scope.ngrequired;
		            });
				},
				template: function (element, attr) {
					var id = attr.id || 'frm-' + attr.name,
						type = attr.type || 'email';
						value = attr.value || '';
					return '<div class="ipt-text-wrappper" ng-class="{\'m-active\': isActive}"> \
								<input ng-required="required" ng-focus="onEnter()" ng-blur="onLeave()" ng-model="model" id="' + id + '" type="' + type + '" name="' + attr.name + '" value="' + value + '" class="ipt ipt-email"> \
								<label ng-click="onEnter()" for="' + id + '">' + attr.label + '</label> \
							</div>';
				}
			}
		});
	}


	return api;
})();