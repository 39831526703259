var util = require('./util');

module.exports = (function() {
    var api = {};


    var gmaps = angular.module('gmaps', [
    ]);


    gmaps.controller('GmapsController', ['$scope', '$rootScope', function ($scope) {
        var vo, lat, lng;
        var json = "https://maps.googleapis.com/maps/api/geocode/json?address=" + $('.data-venue-address').data('addr') + "&key=AIzaSyC2E-FBLsSQ9jA8xlPc2DbP90k9vxjTaYY";
        $.getJSON(json,function(result){
            lat = result.results[0].geometry.location.lat;
            lng = result.results[0].geometry.location.lng;
            vo = {
                venue : $('.data-venue-title').text(),
                desc : $('.data-venue-address').html(),
                lat : lat,
                long : lng
            };

            var mapOptions = {
                zoom: 14,
                center: new google.maps.LatLng(lat, lng),
                mapTypeId: google.maps.MapTypeId.TERRAIN
            }

            $scope.map = new google.maps.Map(document.getElementById('map'), mapOptions);

            $scope.markers = [];
            
            var infoWindow = new google.maps.InfoWindow();
            
            var createMarker = function (info){

                var marker = new google.maps.Marker({
                    map: $scope.map,
                    position: new google.maps.LatLng(info.lat, info.long),
                    title: info.venue
                });
                marker.content = '<div class="infoWindowContent">' + info.desc + '</div>';
                
                google.maps.event.addListener(marker, 'click', function(){
                    infoWindow.setContent('<h2>' + marker.title + '</h2>' + marker.content);
                    infoWindow.open($scope.map, marker);
                });
                
                $scope.markers.push(marker);
                
            }  
            
            createMarker(vo);

        }); 
               
        $scope.openInfoWindow = function(e, selectedMarker){
            e.preventDefault();
            google.maps.event.trigger(selectedMarker, 'click');
        }
    }]);

    api.gmaps = gmaps;

    return api;
})();