var util = require('./util');

module.exports = (function() {
	var api = {};


	var category = angular.module('green-category', [
	]);

	category.controller('CategoryController', ['$scope', '$rootScope', '$element', '$document', '$window', '$timeout', function($scope, $rootScope, $element, $document, $window, $timeout) {
		var panel = this;
		console.log('category');
	}])

	api.category = category;

	return api;
})();
