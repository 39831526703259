module.exports = (function() {
	var api = {};

	api.register = function (form) {
		form.directive('mocaRadio', function(){
			return {
				restricted: 'E',
				transclude: true,
				replace: true,
				scope: {
					model: '=model'
				},
				template: function ($element, $attrs) {
					return '<div class="ipt ipt-radio"> \
								<div  ng-transclude></div> \
								<label for="' + $attrs.for + '">' + $attrs.label + '</label> \
							</div>';

				}
			}
		});
	}


	return api;
})();