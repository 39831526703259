var util = require('./../../util');

module.exports = (function() {
	var api = {};

	api.register = function (form) {
		form.directive('select', ['$timeout', function($timeout){
			return {
				restricted: 'E',
				link: function($scope, $element, $attrs) {
					$timeout(function () {
						if(typeof Selectize != "undefined")
						{
							Selectize.define('input_modify', function(options) {
								var self = this;

								this.setup = (function() {
									var original = self.setup;
									return function() {
							            original.apply(this, arguments);

							            this.$control.find('input').attr('id', '');
							        };
								})();
							});

							$($element).selectize({
								plugins: ['remove_button', 'input_modify']
							});							
						}

					}, 10);
				}
			}
		}])
	}


	return api;
})();