module.exports = (function() {
	var api = {
		isMobileOrSmaller: function () {
			return $(window).width() < 681
		},
		findByProp: function (data, value, prop) {
			prop = prop || 'id';
			return $.grep(data, function(e){ return e[prop] == value; });
		},
		getIndexOf: function (collection, value, prop) {
			prop = prop || 'id';
			return collection.map(function (e) { return e[prop] }).indexOf(value);
		},
		roundTo: function (value, to) {
			to = to || 10;
			return Math.floor(value/to) * to;
		},
		debounce: function(func, wait, immediate) {
			var timeout;
			return function() {
				var context = this, args = arguments;
				var later = function() {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				var callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		},
		getScrollbarWidth: function () {
			return $(window).width();// - $('#siteBody > div').width();
		},
		getRandomInt: function (min, max) {
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}
	};

	return api;
})();
