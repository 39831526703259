module.exports = (function() {
	var api = {};

	var form = angular.module('green-form', [
	]).directive('lazyJs', [function () {
		return {
			restricted: 'A',
			link: function (scope, element) {
				var code = element.text();
				var tmpFunc = new Function(code);

	            scope.$on('GavityFormController::includeContentLoaded', function () {
	            	tmpFunc();

					if(window['gformInitDatepicker']) {
						gformInitDatepicker();
					}

					if (window['gformInitPriceFields']) {
						gformInitPriceFields();
					}
	            })
			}
		}
	}]).controller('GavityFormController', ['$scope', '$rootScope', '$element', '$http', function ($scope, $rootScope, $element, $http) {
		var panel = this,
			formId = 0;	

		$scope.form = '';
		$scope.init = function (id, url) {
			formId = id;
			$scope.form = url + '?action=gf_button_get_form&form_id=' + id
		}

		$scope.$on('$includeContentLoaded', function(e, src){
			if ($('#gform_wrapper_' + formId).length > 0) {
				$scope.$broadcast('GavityFormController::includeContentLoaded');
			}
		});
	}]);

	//registerring componenets
	var radio = require ('./radio');
	radio.register(form);

	var select = require ('./select');
	select.register(form);

	var text = require ('./text');
	text.register(form);

	var email = require ('./email');
	email.register(form);

	var check = require ('./check');
	check.register(form);

	var textarea = require('./textarea');
	textarea.register(form);

	api.form = form;


	return api;
})();