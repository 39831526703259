var util = require('./util');

module.exports = (function() {
	var api = {};


	var menu = angular.module('green-menu', [
	]);

	menu.controller('MenuController', ['$scope', '$rootScope', '$element', '$timeout', function($scope, $rootScope, $element, $timeout) {
		var panel = this;

		$scope.menuClick = function (event) {
			var target = $(event.currentTarget);
			target.addClass('m-active');
			$scope.$emit('menuClick', {target: target});
		};
	}]).directive('jumpMenu', function () {
		return {
			restricted: 'A',
			link: function ($scope, $element, $attrs) {
				$scope.selected = {
					info: false
				};

				$scope.$on('onScroll::onScroll', function (e, args) {
					var pos = $element.parent().offset().top - $('header.site-header').height(),
						sw = util.getScrollbarWidth();

					if (pos < 0) {
						$element.addClass('m-fixed').css({
							'padding-right': sw + 'px'
						});
					} else {
						$element.removeClass('m-fixed').css({
							'padding-right': ''
						});
					}
				});

				$scope.addSelected = function (item) {
					$scope.$apply(function () {
						$scope.selected[item] = true;
					});
				};

				$scope.removeSelected = function (item) {
					$scope.$apply(function () {
						$scope.selected[item] = false;
					});
				};

				$element.find('a').click(function (e) {
					e.preventDefault();

					if (util.isMobileOrSmaller()) {
						var target = $(this).data('target'),
							offset = $(target).offset().top;

						$('html,body').animate({
							'scrollTop': offset - 10
						}, 500);
					} else {
						var target = $(this).data('target'),
							offset = $(target).offset().top - $('.root-element').offset().top;

						$('#siteBody').animate({
							'scrollTop': offset - 60
						}, 500);
					}

					return false;
				});
			
			}
		};
	}).directive('jumpMenuItem', function () {
		return {
			restricted: 'A',
			scope: {},
			link: function ($scope, $element, $attrs) {
				$scope.$on('onScroll::onScroll', function (e, args) {
					if ($element.offset().top - 160 < 0) {
						$scope.$parent.addSelected($attrs.id);
					} else {
						$scope.$parent.removeSelected($attrs.id);
					}
				});
			}
		};
	});

	api.menu = menu;

	return api;
})();
